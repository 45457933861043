<template>
<div>
  <Header></Header>
  <BGImg v-if="section.banner" :img-url="section.banner"></BGImg>
  <BGImg v-else></BGImg>

  <div style="max-width: 900px;margin: 10px auto 0 auto">
<!--    <div style="display: flex;justify-content: flex-end">-->
<!--      <RoutePath style="margin-right: auto"></RoutePath>-->
<!--      <div style="float: right;display: flex">-->
<!--        <TitleTop v-for="(name,index) in titleNames"-->
<!--                  :content="name"-->
<!--                  :active="active===index"-->
<!--                  @click.native="change(index)"></TitleTop>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div style="max-width: 1200px;margin: auto;border-bottom: 1px solid rgb(247, 248, 249); "></div>
  <div class="main">
    <div class="menu-left">
      <TitleLeft v-for="(name,index) in titleNames"
                 :content="name"
                 :active="active==index"
                 @click.native="change(index)"></TitleLeft>
      <TitleLeft
          v-if="section.sectionId===1"
          :content="{title:'视频中心'}"
          :active="active==10"
          @click.native="active=10;link('/videoList');"></TitleLeft>
    </div>
    <div class="doclist">
      <div v-if="doclist.length === 0" style="margin: 20px 50px;color: #aaaaaa">暂无文章</div>
      <DocItem :data="item" v-for="(item,index) in doclist"></DocItem>
    </div>
  </div>
  <div class="page">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10,15,20,50,100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="doclist_len">
    </el-pagination>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import RoutePath from "@/components/DocList/RoutePath"
import TitleTop from "@/components/DocList/TitleTop";
import TitleLeft from "@/components/DocList/TitleLeft";
import DocItem from "@/components/DocList/docItem";
import Header from "@/components/Header";
import BGImg from "@/components/BGImg";
import Footer from "@/components/Footer";
import {mapMutations} from "vuex";
export default {
  name: "DocList",
  components: {Footer, BGImg, Header, DocItem, TitleLeft, TitleTop, RoutePath},
  data(){
    return{
      section:{
        sectionId: this.$route.query.id,
        title: "新闻",
        banner: null,
      },
      paths:[
        {name:"首页",path:'/'},
        {name:"新闻",path:'/'},
        {name:"动态",path:'/'},
      ],
      titleNames:[
        {title:"公司动态",},
        {title:"集团资讯",},
        {title:"公司动态",},
        {title:"公司动态",},
        {title:"公司动态",},
      ],
      doclist:[
        {
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },{
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },{
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },{
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },
      ],
      doclist_len:0,
      currentPage:1,
      pageSize:15,
      active:this.$route.query.categoryid?this.$route.query.categoryid:0,
    }
  },
  computed:{

  },
  methods:{
    ...mapMutations([
      'setDocListPaths'
    ]),
    change(index){
      this.active = index
      this.loadDocList(this.titleNames[index].categoryId)
      this.paths[2] = {name:this.titleNames[index].title,path: null}
      this.setDocListPaths(this.paths)
      console.log(this.paths)
    },
    changePage(){
      console.log(this.pageSize,this.currentPage)
      this.loadDocList(this.titleNames[this.active].categoryId)
    },
    handleSizeChange(val) {
      console.log(val,typeof (val))
      this.pageSize = val;
      this.changePage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.changePage();
    },
    loadDocList(categoryId){
      this.axios.get('/api/document/user/article/list/'+categoryId,{
        params:{
          pageSize:this.pageSize,
          currentPage:this.currentPage,
        }
      }).then(res=>{
        this.doclist = res.data.results
        this.doclist_len = res.data.count
      })
    },
    link(url){
      window.location.href = url;
    }
  },
  mounted() {

    //获取section name 修改paths
    this.axios.get('/api/document/user/section/restrieve/'+this.section.sectionId)
        .then(res=>{
          this.section = res.data
          this.paths = [
            {name:"首页",path:'/'},
            {name:res.data.title,path:'/doclist?id='+this.section.sectionId},
          ]
          // 获取category列表
          this.axios.get('/api/document/user/category/list/'+this.section.sectionId)
              .then(res=>{
                this.titleNames = res.data
                this.doclist = []
                if(this.titleNames.length > 0){
                  this.loadDocList(this.titleNames[this.active].categoryId)
                  this.paths.push(
                      {name:this.titleNames[this.active].title,path: null}
                  )
                }
                this.setDocListPaths(this.paths)
              })
        })
  }
}
</script>

<style scoped>
.main{
  max-width: 950px;
  margin: auto;
  display: flex;
}
.menu-left{
  width: 100%;
  padding-top: 30px;
  max-width: 230px;
  text-align: left;
}
.doclist{
  max-width: 640px;
  margin: 10px 20px;
}
.page{
  margin: 20px auto;
}
</style>