<template>
<div>
  <div class="name_container">
    <div v-if="!active">
      <div class="name">{{content.title}}</div>
    </div>
    <div v-if="active">
      <div class="name name_active">{{content.title}}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "TitleTop",
  props: {
    content: {
      // 定义接收的类型 还可以定义多种类型 [String, Undefined, Number]
      // 如果required为true,尽量type允许undefined类型，因为传递过来的参数是异步的。或者设置默认值。
      type: Object,
      // 定义是否必须传
      required: true,
      // 定义默认值
      default: '暂无'
    },
    active:{
      type: Boolean,
    }
  },
}
</script>

<style scoped>
.name_container{
  margin-left: 5px;
}
.name{
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 0.8;
  font-size: 14px;
  line-height: 48px;
  cursor: pointer;
  box-sizing: border-box;
}
.name_active{
  border-bottom:2px solid  var(--primary_color);
}
.name:hover{
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}
</style>