<template>
<div style="text-align: left">
  <div @click="link(path.path)" v-for="(path,index) in docListPaths" class="container_right">
    <i v-if="index === 0" class="el-icon-s-home"></i>
    {{path.name}}
    <span v-if="index !== docListPaths.length-1" style="font-weight: bolder"> >&nbsp; </span>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "RoutePath",
  computed:{
    ...mapState([
        'docListPaths'
    ])
  },
  props:{
    // docListPaths:[]
  },
  methods:{
    link(url){
      if(url)
        window.location.href = url;
    }
  }
}
</script>

<style scoped>
.container_right{
  display: inline-block;cursor:pointer;
  line-height: 50px;
}
</style>